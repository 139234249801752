window.end_form_captcha_id,
  window.newsletter_captcha_id;

const captcha_sitekey = process.env.CAPTCHA_SITEKEY_CHECKBOX,
  captcha_sitekey2 = process.env.CAPTCHA_SITEKEY_INVISIBLE,
  acBaseApiUrl = process.env.AC_BASE_API_URL
const websiteContactApiUrl = process.env.WEBSITE_CONTACT_API_URL;
import intlTelInput from 'intl-tel-input';
import * as  public_email_domains from "~/static/resources/json/free_email_provider_domains.json";

//

function get_prevsite() {
  const urlParams = new URLSearchParams(window.location.search);
  let source = urlParams.get('utm_source');
  let medium = urlParams.get('utm_medium');

  // Verifica sessionStorage para el referente original
  let ref = sessionStorage.getItem('original_ref');
  if (!ref) {
    // Si no se almacena ningún referente original, utiliza document.referrer o 'direct traffic' 
    ref = document.referrer || 'direct traffic';
    sessionStorage.setItem('original_ref', ref);
  }

  // Verifica sessionStorage para el sitio anterior
  let prevsite = sessionStorage.getItem('prevsite');

  // Almacena el document.referrer actual como el sitio anterior para la próxima visita
  sessionStorage.setItem('prevsite', document.referrer || 'direct traffic');

  return { ref, prevsite, source, medium };
}

//

function field_success(e) {
  let r = e.attr("id");
  $(e).addClass("success");
  $("#error-" + r).remove();
}
window.controls_fields = function controls_fields(e, r) {
  let t = e.attr("id"),
    a = e.attr("data-placement"),
    o = $("#spanish").hasClass("active"),
    i = $(".right").hasClass("active"),
    val = $(e).val(),
    req = $(e).attr("data-required") === "true",
    hasVal = val != null && val !== ""
  if (req && !hasVal) {
    let id = $(e).attr("id"),
      r = $("#spanish").hasClass("active"),
      t = $(".right").hasClass("active");
    $(e).addClass("error");
    return ($(".input-group-" + id).append(
      "<p id='error-" + id + "' class='error-field'>Campo requerido</p>"
    ),
      $("#error-" + id).next(),
      !1
    )
  }
  if (hasVal) {
    switch (t) {
      case "fullname":
      case "firstname":
      case "lastname":
        return !(
          !field_length(e, 3, 100) ||
          !only_letters(e) ||
          (field_success(e), 0)
        );
      case "organization":
        return !(
          !field_length(e, 3, 30) ||
          !validate_company(e) ||
          (field_success(e), 0)
        );
      case "company-size":
      case "industry":
        return !(
          (field_success(e), 0)
        );
      case "role":
        return !(
          !field_length(e, 3, 100) ||
          // !only_numbers(e) ||
          !only_letters(e) ||
          (field_success(e), 0)
        );
      case "email":
        return !(
          !validate_email(e) ||
          !validate_domain_email(e) ||
          (field_success(e), 0)
        );
      case "email-careers":
        return !(
          !field_length(e, 3, 100) ||
          !validate_email(e) ||
          (field_success(e), 0)
        );
      case "phone":
        return (
          !field_length(e, 5, 20) ||
          !validate_phone(e) ||
          (field_success(e), !0)
        );
      case "message":
        return !(
          !field_length(e, 15, 1e3) ||
          !only_numbers(e) ||
          (field_success(e), 0)
        );
      case "field_34":
        return (
          !!e.is(":checked") ||
          (o || i
            ? $("#label_field_34").after(
              "<p id='error-" +
              t +
              "' class='error-field'>Para continuar, por favor acepte nuestras políticas de privacidad</p>"
            )
            : $("#label_field_34").after(
              "<p id='error-" +
              t +
              "' class='error-field'>Para continuar, por favor acepte nuestras políticas de privacidad</p>"
            ),
            !1)
        );
      case "country":
        return !(
          (field_success(e), 0)
        );
    }
  }
  return true;
}
function field_length(e, r, t) {
  let a = e.val().length,
    o = e.attr("data-placement"),
    i = e.attr("id"),
    s = $("#spanish").hasClass("active"),
    n = $(".right").hasClass("active");
  if (a < r) {
    $(e).addClass("error");
    $(".input-group-" + i).append(
      "<p id='error-" +
      i +
      "' class='error-field'>" +
      o +
      " debe contener un mínimo de " +
      r +
      " caracteres</p>"
    );
    return !1;
  }
  if (a > t) {
    $(e).addClass("error");
    $(".input-group-" + i).append(
      "<p id='error-" +
      i +
      "' class='error-field'>" +
      o +
      " debe contener un máximo de  " +
      t +
      " caracteres</p>"
    );

    return !1;
  }
  return !0;
}
function only_letters(e) {
  let r = e.attr("id"),
    t = e.attr("data-placement"),
    a = $("#spanish").hasClass("active"),
    o = $(".right").hasClass("active");
  if (/^[a-zA-ZÀ-ÿ ]*$/.test(e.val())) return !0;
  $(e).addClass("error");
  $(".input-group-" + r).append(
    "<p id='error-" +
    r +
    "' class='error-field'>" +
    t +
    " debe contener solo letras.</p>"
  );

  return !1;
}
function only_numbers(e) {
  let r = e.attr("id"),
    t = e.attr("data-placement"),
    a = $("#spanish").hasClass("active"),
    o = $(".right").hasClass("active");
  if (/^[0-9]+$/.test(e.val())) {
    $(e).addClass("error");
    $(".input-group-" + r).append(
      "<p id='error-" +
      r +
      "' class='error-field'>" +
      t +
      " debe contener letras.</p>"
    );

    return !1;
  }
  return !0;
}
function validate_company(e) {
  let o = e.attr("id"),
    r = e.attr("data-placement");
  isValid = /^([A-Za-zÑñÁáÉéÍíÓóÚú]+[\'\@\&\.\-]{0,1}[0-9A-Za-zÑñÁáÉéÍíÓóÚú]*[\'\@\&\.\-]{0,1}[0-9A-Za-zÑñÁáÉéÍíÓóÚú]*[\'\@\&\.\-]{0,1}[0-9A-Za-zÑñÁáÉéÍíÓóÚú]*)(\s+([0-9A-Za-zÑñÁáÉéÍíÓóÚú&.]+[\'\@\&\.\-]{0,3}[A-Za-zÑñÁáÉéÍíÓóÚú]*))*$/.test(e.val().trim());
  errorMessage = `${r} debe comenzar por un carácter alfabético y solo permite el ingreso de los siguientes símbolos especiales: ' | @ | & | - | .`;
  return isValid
    ? (field_success(e), !0)
    : ($(e).addClass("error"),
      $(".input-group-" + o).append(
        `<p id='error-${o}' class='error-field'>${errorMessage}</p>`
      ),
      !1);
}
function validate_phone(e) {
  let o = e.attr("id"),
    r = e.attr("data-placement");
  let isValid = true;
  let errorMessage = "";
  const intTelInstance = window.intlTelInputGlobals.getInstance(e[0]);
  if (intTelInstance) {
    const countryData = intTelInstance.getSelectedCountryData();
    if (!countryData && !countryData.dial) {
      isValid = false;
      errorMessage = "Por favor seleccione el prefijo telefónico de su país"
    }
  }
  //  isValid = /^[+0-9\s-]+$/.test(e.val());
  isValid = /^[0-9]+$/.test(e.val());
  errorMessage = `${r} debe contener únicamente números.`;
  return isValid
    ? (field_success(e), !0)
    : ($(e).addClass("error"),
      $(".input-group-" + o).append(
        `<p id='error-${o}' class='error-field'>${errorMessage}</p>`
      ),
      !1);
}
function validate_email(e) {
  let r = e.val(),
    t = e.attr("id"),
    a = $("#spanish").hasClass("active"),
    o = $(".right").hasClass("active"),
    isValid = !!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      r
    ),
    errorMsg = "El correo es inválido. Por favor, verifique que esté bien escrito."
  if (!isValid) {
    $(e).addClass("error");
    if (r.indexOf("@") == -1) {
      errorMsg = "El correo es inválido. No se encontro el caracter \"@\".";
    }
    $(".input-group-" + t).append(
      `<p id='error-${t}' class='error-field'>${errorMsg}</p>`
    )
    return false;
  }
  return true;
}
$(".error-pdf").hide(),
  $(document).ready(function () {
    $(".loading_animation").hide(),
      $(".form-thank-you").hide(),
      $(".w3-light-grey").hide(),
      $(".error-form").hide(),
      $(".input-file-progress-bar").hide(),
      $(".error-email-form").hide()
  }),
  window.addEventListener("load", function () {
    set_adwords_variables();
  }),
  $(document).on("keypress", "input", function () {
    let e = $(this).val().replace(/\s+/g, " ");
    $(this).val(e);
  }),
  $(document).on("focus", "input", function () {
    let e = $(this).attr("id");
    $("#error-" + e).remove();
    $(this).removeClass("error");
    $(this).removeClass("success");
  }),
  $(document).on("focus", "textarea", function () {
    let e = $(this).attr("id");
    $("#error-" + e).remove();
    $(this).removeClass("error");
    $(this).removeClass("success");
  }),
  $(document).on("blur", "input", function () {
    let e = $(".form_contact").attr("id");
    return controls_fields($(this), e), !0;
  }),
  $(document).on("blur", "textarea", function () {
    controls_fields($(this))
  }),
  $(document).on("change", "select", function () {
    let e = $(this).attr("id");
    $("#error-" + e).remove();
    $(this).removeClass("error");
    $(this).removeClass("success");
    controls_fields($(this))
  }),
  $(document).on("change", "#field_34", function () {
    $("#error-field_34").remove();
  });
function validate_domain_email(e) {
  let r = e.val(),
    t = e.attr("id");
  var a = r.substr(r.lastIndexOf("@") + 1, r.length - 1);
  return (
    (a = a.toLowerCase()),
    -1 === public_email_domains.indexOf(a) ||
    ($(e).addClass("error"),
      $(".input-group-" + t).append(
        "<p id='error-" +
        t +
        "' class='error-field'>Por favor ingrese su correo corporativo.</p>"
      ),
      !1)
  );
}
function move() {
  var e = document.getElementById("myBar"),
    r = 0,
    t = setInterval(function () {
      r >= 100 ? clearInterval(t) : (r++, (e.style.width = r + "%"));
    }, 0);
}
function move_size(e) {
  let r,
    t = Math.round(e / 1048576);
  t < 1 && (r = Math.round(e / 1024)),
    r
      ? ((e = r), $(".size-pdf").html(r + " KB"))
      : ($(".size-pdf").html(t + " MB"), (e = t));
  let a = 0,
    o = setInterval(function () {
      a > e ? clearInterval(o) : $(".size-upload").html(a++);
    }, 10);
}
window.closeUploadPdf = function () {
  $("#cv-upload").val(""),
    $(".input-file-progress-bar").hide(),
    $(".label-input").show(),
    $(".input-file-inner").removeClass("success error");
}
window.control_submit_form = function (event) {
  event.preventDefault();
  let e = $("#fullname"),
    r = $("#organization"),
    a = $("#email"),
    o = $("#message"),
    i = $("#phone"),
    s = $("#field_34"),
    country = $("#country"),
    form = $("form[name=main-form]"),
    n = !0;
  $(form).find(".error-field").remove();
  if (!grecaptcha.getResponse(window.end_form_captcha_id)) {
    $("#end_form_captcha").after(
      "<p id='error-captcha' class='error-field'>El captcha esta vacio o incompleto</p>"
    );
    n = !1;
  }
  controls_fields(e) || (n = !1),
    controls_fields(r) || (n = !1),
    controls_fields(a) || (n = !1),
    controls_fields(o) || (n = !1),
    controls_fields(i) || (n = !1),
    controls_fields(s) || (n = !1),
    controls_fields(country) || (n = !1),
    n && submit_main_form();
}
function submit_main_form() {
  let e = $("#fullname"),
    r = $("#organization"),
    a = $("#email"),
    phone = $("#phone"),
    phoneInstance = window.intlTelInputGlobals.getInstance(phone[0]),
    i = $("#message"),
    country = $("#country"),
    s = $("form[name=main-form]"),
    n = "",
    l = "",
    c = grecaptcha.getResponse(window.end_form_captcha_id),
    d = "",
    /*
    gdprVal34 = "",
    */
    url = "",
    device = "",
    formId = "";
  (d = $(s).attr("data-link")),
    /*
    (gdprVal34 = $("#field_34").val()),
    get_ref() && (n = get_ref()),
    get_prevsite() && (l = get_prevsite()),
    */
    (formId = s.attr("id")),
    (url = window.location.href),
    (device = get_device_type());
  //
  let referrerData = get_prevsite();
  ref = referrerData.ref;
  prevsite = referrerData.prevsite;
  let source = referrerData.source || "";
  let medium = referrerData.medium || "";
  let gdprVal34 = $("#field_34").prop("checked");
  let suscription = $("#field_suscription").prop("checked");
  //

  let phoneNumber = phone.val();
  if (phoneInstance && phoneInstance.getSelectedCountryData())
    phoneNumber = `+${phoneInstance.getSelectedCountryData().dialCode}${phone.val()}`;
  let p = {
    firstname: e.val(),
    company: r.val(),
    email: a.val(),
    comments: i.val(),
    phone: phoneNumber,
    country: country.val(),
    form: formId,
    url: url,
    ref: ref,
    prevsite: prevsite,
    device: device,
    //
    source: source,
    medium: medium,
    suscription: suscription,
    //
    captcharesponse: c,
    gdpr2019: gdprVal34,
  };

  $.ajax({
    type: "POST",
    url: `${acBaseApiUrl}/add-contact-to-form-with-captcha`,
    data: JSON.stringify(p),
    contentType: "application/json",
    beforeSend: function () {
      $(".btn-contact").addClass("loading")
    },
    success: function (e) {
      if (e.errorMessage) {
        $(s).find('.btn-contact').removeClass("loading");
        $(s).find('.div-submit').append("<p class='error-field error-field-large'>Ocurrió un error, por favor intente de nuevo.</p>")
      }
      else {
        $(s)[0].reset();
        window.location.href = d;
      }
    },
    error: function (e) {
      $(s).find('.btn-contact').removeClass("loading");
      $(s).find('.div-submit').append("<p class='error-field error-field-large'>Ocurrió un error, por favor intente de nuevo.</p>")
    },
  });
}
window.control_form_download = function (event) {
  event.preventDefault();
  $(".error-field").remove();
  var e = $("#fullname"),
    t = $("#organization"),
    a = $("#email"),
    o = $("#role"),
    i = $("#field_34");
  let res = !0;
  if (!grecaptcha.getResponse(window.end_form_captcha_id)) {
    $("#end_form_captcha").after(
      "<p id='error-captcha' class='error-field'>The captcha is missing or wrong</p>"
    );
    res = !1;
  };
  controls_fields(e) || (res = !1),
    controls_fields(t) || (res = !1),
    controls_fields(o) || (res = !1),
    controls_fields(a, "103") || (res = !1),
    controls_fields(i) || (res = !1),
    res && submit_main_form_download();
}
function submit_main_form_download() {
  let e = $("#fullname"),
    t = $("#organization"),
    a = $("#role"),
    o = $("#email"),
    form = $("form[name=main-form]"),
    i = "",
    s = "",
    n = "",
    gdprVal34 = "",
    url = "",
    device = "",
    l = $(form).attr("data-link"),
    captcha = grecaptcha.getResponse(window.end_form_captcha_id);
  (gdprVal34 = $("#field_34").val()),
    get_ref() && (s = get_ref()),
    get_prevsite() && (n = get_prevsite()),
    (i = form.attr("id")),
    (url = window.location.href),
    (device = get_device_type());
  let c = {
    name: e.val(),
    jobtitle: a.val(),
    company: t.val(),
    email: o.val(),
    form: i,
    url: url,
    ref: s,
    prevsite: n,
    device: device,
    desiredposition: "",
    gdpr2019: gdprVal34,
    captcharesponse: captcha
  };
  $.ajax({
    type: "POST",
    url: `${acBaseApiUrl}/add-contact-to-form-with-captcha`,
    data: JSON.stringify(c),
    contentType: "application/json",
    beforeSend: function () {
      $(form).find("._form-error").hide();
      $(form).find('.btn-contact').addClass("loading")
    },
    success: function (e) {
      if (e.errorMessage) {
        $(form).find('.btn-contact').removeClass("loading");
        $(form).find('.div-submit').append("<p class='error-field error-field-large'>Ocurrió un error, por favor intente de nuevo.</p>")
      }
      else {
        form[0].reset();
        window.location.href = l;
      }
    },
    error: function (e) {
      $(i).find('.btn-contact').removeClass("loading");
      $(i).find("._form-error").html(
        "Ocurrió un error, por favor intente de nuevo."
      );
      $(i).find("._form-error").show();
    },
  });
}
window.get_device_type = function () {
  return navigator.userAgent.match(/Tablet|iPad|iPod|Android/i) &&
    window.innerWidth <= 1280 &&
    window.innerHeight <= 800 &&
    !navigator.userAgent.match(/Mobile/i)
    ? "Tablet"
    : navigator.userAgent.match(
      /Android|BlackBerry|Tablet|Mobile|iPhone|iPad|iPod|Opera Mini|IEMobile/i
    )
      ? "Mobile"
      : "Computer";
}
window.newsletter_forms = async function (event) {
  event.preventDefault();
  let r = $("#email-nl"),
    form = $(".footer-container ._form");
  form.find("._form-error").hide();
  form.find("input").removeClass("error success");
  form.find(".error-field").html("");
  form.find(".error-field").hide();
  if (r.val()) {
    validate_email(r)
      ? (r.addClass("success"),
        grecaptcha.execute(window.newsletter_captcha_id))
      : (r.addClass("error"),
        r.parent()
          .find(".error-field")
          .html("The email seems invalid. Please check it's correctly typed."),
        r.parent().find(".error-field").show())
  }
  else {
    r.addClass("error"),
      r.parent().find(".error-field").html("Campo requerido."),
      r.parent().find(".error-field").show()
  }
}

function submit_newsletter_form() {
  let r = $("#email-nl"),
    t = "",
    a = "",
    o = $("#93").attr("data-link"),
    successurl = "";
  let form = $(".footer-container ._form");
  (successurl = o),
    get_ref() && (t = get_ref()),
    get_prevsite() && (a = get_prevsite());
  const userData = {
    email: r.val(),
    form: "93",
    url: window.location.href,
    ref: t,
    prevsite: a,
    device: get_device_type(),
    captcharesponse: grecaptcha.getResponse(window.newsletter_captcha_id),
  };
  let btnSubmit = $(form).find("button[type=\"submit\"]");
  $.ajax({
    type: "POST",
    url: `${acBaseApiUrl}/add-contact-to-form-with-captcha`,
    data: JSON.stringify(userData),
    contentType: "application/json",
    beforeSend: function () {
      form.find("._form-error").hide();
      btnSubmit.addClass("loading");
    },
    success: function (e) {
      form[0].reset();
      window.location.href = successurl;
    },
    error: function (e) {
      btnSubmit.removeClass("loading");
      form.find("._form-error").html(
        "Ocurrió un error, por favor intente de nuevo."
      );
      form.find("._form-error").show();
    },
  });
}
function set_adwords_variables() {
  var e = get_ref_parameter();
  e || (e = ""), window.sessionStorage.setItem("ref", e);
  var r = document.referrer;
  get_prevsite() ||
    (r || (r = "direct traffic"), window.sessionStorage.setItem("prevsite", r));
}
window.get_prevsite = function () {
  return window.sessionStorage.getItem("prevsite");
}
function get_ref_parameter() {
  return getParameterByName("ref", "");
}
window.get_ref = function () {
  return window.sessionStorage.getItem("ref");
}
function createCookie(e, r, t) {
  var a = "";
  if (t) {
    var o = new Date();
    o.setTime(o.getTime() + 24 * t * 60 * 60 * 1e3),
      (a = "; expires=" + o.toUTCString());
  }
  document.cookie = e + "=" + r + a + "; path=/";
}
function readCookie(e) {
  for (
    var r = e + "=", t = document.cookie.split(";"), a = 0;
    a < t.length;
    a++
  ) {
    for (var o = t[a]; " " == o.charAt(0);) o = o.substring(1, o.length);
    if (0 == o.indexOf(r)) return o.substring(r.length, o.length);
  }
  return null;
}
function eraseCookie(e) {
  createCookie(e, "", -1);
}
function getParameterByName(e, r) {
  r || (r = window.location.href), (e = e.replace(/[\[\]]/g, "\\$&"));
  var t = new RegExp("[?&]" + e + "(=([^&#]*)|&|#|$)").exec(r);
  return t ? (t[2] ? decodeURIComponent(t[2].replace(/\+/g, " ")) : "") : null;
}
window.init_captchas = function () {
  if (-1 === document.location.href.indexOf("stesting.abstracta.us")) {
    if (document.getElementById("end_form_captcha")) {
      var e = grecaptcha.render("end_form_captcha", {
        sitekey: captcha_sitekey,
        callback: () => {
          $("#end_form_captcha").parent().find(".error-field").remove();
        }
      });
      window.end_form_captcha_id = e;
    }
    if (document.getElementById("newsletter_captcha")) {
      var r = grecaptcha.render("newsletter_captcha", {
        sitekey: captcha_sitekey2,
        size: "invisible",
        callback: submit_newsletter_form,
        badge: "inline"
      });
      window.newsletter_captcha_id = r;
    }
  }
}
$(document).on("change", "#cv-upload", function (e) {
  $(".input-file-progress-bar").hide();
  $(".error-pdf").hide(),
    $(".input-file-inner").removeClass("error"),
    $(".label-input").show();
  if ($(this)[0].files.length) {
    let r = $(this),
      t = r[0].files[0].size,
      a = t / 1048576;
    if (
      ((a = Math.round(a)),
        a > 10)
    )
      return (
        $(".error-pdf").show(),
        $(".input-file-inner").addClass("error"),
        $(".error-pdf").html("The file you are trying to upload exceeds 10MB"),
        !1
      );
    $(".error-pdf").hide(),
      $(".input-file-inner").removeClass("error"),
      $(".input-file-progress-bar").show(),
      $(".label-input").hide(),
      $(".input-file-inner").addClass("success"),
      $(".name-pdf").html(r.val().replace(/C:\\fakepath\\/i, ""));
  }
});

$(document).on("click", ".close", function () {
  $(".url").text(""), $(".url").addClass("hidden");
});


window.end_page_form = async function (event) {
  event.preventDefault();
  var e = !0;
  $(".error-field").remove(), $("#error-captcha").remove();
  var r = $("#fullname"),
    t = $("#email-careers"),
    y = $("#country"),
    a = $("#role"),
    phone = $("#phone"),
    phoneInstance = window.intlTelInputGlobals.getInstance(phone[0]),
    m = $("#message"),
    i = $("#field_34"),
    s = $("#cv-upload")[0].files[0],
    n = "",
    successPage = $("form[name='main-form']").attr("data-link");
  let l = grecaptcha.getResponse(window.end_form_captcha_id);
  let c = $("#spanish").hasClass("active"),
    d = $(".right").hasClass("active"),
    ref;
  if (
    (controls_fields(r) || (e = !1),
      controls_fields(t) || (e = !1),
      controls_fields(y) || (e = !1),
      controls_fields(a) || (e = !1),
      controls_fields(phone) || (e = !1),
      controls_fields(i) || (e = !1),
      controls_fields(m) || (e = !1),
      get_ref() && (ref = get_ref()),
      get_prevsite() && (n = get_prevsite()),
      l ||
      (c || d
        ? $("#end_form_captcha").after(
          "<p id='error-captcha' class='error-field'>El captcha esta vacio o incompleto</p>"
        )
        : $("#end_form_captcha").after(
          "<p id='error-captcha' class='error-field'>The captcha is missing or wrong</p>"
        ),
        (e = !1)),
      s)
  ) {
    let e = $("#cv-upload")[0].files[0].size;
    (e /= 1048576) &&
      e > 10 &&
      ($(".error-pdf").html("The file you are trying to upload exceeds 10MB").show(),
        $(".input-file-inner").addClass("error"));
  }
  if (e) {
    var p = window.location.href,
      f = get_device_type();
    let phoneNumber = phone.val();
    if (phoneInstance && phoneInstance.getSelectedCountryData())
      phoneNumber = `+${phoneInstance.getSelectedCountryData().dialCode}${phone.val()}`;

    const fullName = r.val();
    const desiredPosition = a.val()
    const email = t.val();
    const country = y.val();
    const comments = m.val();
    const cvFile = $("#cv-upload")[0].files[0];
    var acUserData = {
      name: fullName,
      desiredposition: desiredPosition,
      email,
      country,
      phone: phoneNumber,
      comments,
      form: "135",
      url: p,
      ref,
      prevsite: n,
      device: f,
      captcharesponse: l,
      gdpr2019: "I've read and agree to Abstracta's privacy policy.",
    };
    $(".btn-contact").addClass("loading");
    contactCareers({
      fullName, desiredPosition, email, country, phone: phoneNumber,
      cvFile, contactUrl: p, captchaResponse: l, acUserData
    }).then((() => {
      $(s).find('.btn-contact').removeClass("loading");
      $(".form_contact")[0].reset();
      window.location.href = successPage;
    })).catch((e) => {
      $(s).find('.btn-contact').removeClass("loading");
      $(s).find('.div-submit').append("<p class='error-field error-field-large'>Ocurrió un error, por favor intente de nuevo.</p>")
    })
  }
}

async function uploadCV(cvFile) {
  const { uploadURL, fileName } = await getUploadUrlCV();
  return new Promise((resolve, reject) => {
    $.ajax({
      url: uploadURL,
      method: "PUT",
      contentType: "application/pdf",
      data: cvFile,
      processData: false,
      success: () => {
        resolve(fileName);
      },
      error: reject,
    });
  })
}

function getUploadUrlCV() {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${websiteContactApiUrl}/get-upload-url/cv`,
      success: resolve,
      error: reject,
    });
  })
}

function contactCareers({ fullName, desiredPosition, email, country, phone, cvFile, contactUrl, captchaResponse, acUserData }) {
  return new Promise((resolve, reject) => {
    uploadCV(cvFile).then(uploadedFileName => {
      const data = {
        fullName, desiredPosition, email, country, phone,
        cvPath: uploadedFileName, contactUrl, captchaResponse,
        acUserData
      };
      $.ajax({
        url: `${websiteContactApiUrl}/careers-send-email`,
        method: "POST",
        data: JSON.stringify(data),
        success: () => {
          resolve();
        },
        error: () => {
          reject();
        },
      });
    })
  })
}

function initPhoneInput() {
  const telInput = document.querySelector("#phone");
  if (telInput) {
    intlTelInput(telInput, {
      separateDialCode: true,
      nationalMode: false,
      autoHideDialCode: false,
      initialCountry: "auto",
      geoIpLookup: function (success, failure) {
        $.get("https://ipinfo.io", function () { }, "jsonp").always(function (resp) {
          var countryCode = (resp && resp.country) ? resp.country : "us";
          success(countryCode);
        });
      }
    });
  }
  telInput.addEventListener("open:countrydropdown", () => { telInput.closest(".input-text").classList.add("focus") });
  telInput.addEventListener("focus", () => { telInput.closest(".input-text").classList.add("focus") });
  telInput.addEventListener("blur", () => {
    if (document.querySelector("#phone").value) {
      telInput.closest(".input-text").classList.remove("focus");
      telInput.closest(".input-text").classList.add("error");
      telInput.closest("#phone").classList.contains('success') ? telInput.closest(".input-text").classList.add("success") : telInput.closest(".input-text").classList.remove("success");
    } else {
      telInput.closest(".input-text").classList.remove("focus");
      telInput.closest(".input-text").classList.remove("error");
      telInput.closest(".input-text").classList.remove("success");
    }
  });
}

function initCountryInput() {
  const countryInput = document.querySelector("#country");
  countryInput.closest(".input-text").classList.add("focus");
  if (countryInput) {
    const countryInputIti = intlTelInput(countryInput, {
      nationalMode: true,
      autoHideDialCode: true,
      initialCountry: "auto",
      geoIpLookup: function (success, failure) {
        $.get("https://ipinfo.io", function () { }, "jsonp").always(function (resp) {
          var countryCode = (resp && resp.country) ? resp.country : "us";
          success(countryCode);
        });
      },
    });
    const setCountry = () => {
      const countryData = countryInputIti.getSelectedCountryData();
      countryInput.value = countryData.name || "";
      controls_fields($(countryInput));
      const container = countryInput.closest(".input-text");
      container.classList.remove("focus");
      if (countryInput.value) container.classList.add("valid", "success");
      else container.classList.remove("valid", "success");
    }
    countryInput.addEventListener("countrychange", setCountry);
    countryInput.addEventListener("open:countrydropdown", () => { const container = countryInput.closest(".input-text").classList.add("focus") });
    countryInput.addEventListener("close:countrydropdown", setCountry);
    countryInput.addEventListener("blur", (e) => {
      setCountry();
    });
    countryInput.addEventListener("keydown", (e) => {
      e.preventDefault();
      return false
    });
    countryInput.addEventListener("focus", (e) => {
      countryInput.closest(".input-text").classList.add("focus");
      document.activeElement.blur()
    });
    countryInput.addEventListener("click", function (e) {
      e.preventDefault();
      countryInput.closest(".iti").querySelector(".iti__selected-flag").dispatchEvent(new Event("click"));
      return false;
    })
  }
}


window.addEventListener("load", () => {
  document.querySelectorAll("input[type=text]").forEach((e) =>
    e.addEventListener("keypress", (e) => {
      13 == e.keyCode && e.preventDefault();
    })
  );
  initPhoneInput();
  initCountryInput();
});

(function loadReCaptcha() {
  const forms = document.getElementsByTagName('form');
  let injectTimeout;
  // trigger loading api.js (recaptcha.js) script
  var injectReCaptcha = () => {
    if (injectTimeout) clearTimeout(injectTimeout);
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://www.google.com/recaptcha/api.js?hl=es&onload=init_captchas&render=explicit';
    head.appendChild(script);

    for (let i = 0; i < forms.length; i++) {
      forms[i].removeEventListener('focus', injectReCaptcha, true)
    }

  };
  // add initial event listener to HTML form
  for (let i = 0; i < forms.length; i++) {
    forms[i].addEventListener('focus', injectReCaptcha, { capture: true })
  }
  window.addEventListener("DOMContentLoaded", () => {
    injectTimeout = setTimeout(injectReCaptcha, 10000)
  })
})()